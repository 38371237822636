<template>
  <b-container class="my-4">
    <b-row class="text-center mb-4" align-h="center" v-if="loading">
      <b-col cols="1">
        <i class="fa fa-spinner fa-pulse fa-lg fa-fw"></i>
      </b-col>
    </b-row>
    <b-row class="text-center mb-4" align-h="center" v-if="!loading && !permission.read">
      <b-col>
        <h3>You do not have permissions for this page</h3>
      </b-col>
    </b-row>
    <b-row v-if="!loading && permission.read">
      <b-col>
        <b-card >
          <b-container>
            <b-row>
              <b-col>
                <p><b-link :to="{ name: 'Tags' }">all Tags</b-link></p>
                <h2>
                  <b-button class="mr-2" v-if="permission.edit" variant="outline-primary" :to="{ name: 'FormEdit', params: { id: item.id, model: 'tag' }}">Edit</b-button>
                  {{item.name}}
                  <span v-if="$config.BUILD === 'full'">
                    <subscription
                      :id="item.id"
                      content="labels"
                    />
                  </span>
                </h2>
                <div>{{item.description}}</div>
                <div v-if="item.regtopics.length > 0">
                  <h4 class="mt-5">Topics</h4>
                  <div v-for="row in item.regtopics" :key="'regtopic'+row.id" class="mb-1">
                    <router-link :to="{ name: 'Topic', params: { id: row.id }}">{{row.name}}</router-link>
                  </div>
                </div>
                <div no-body v-if="news.length > 0">
                    <h4 class="mt-5">News</h4>
                    <div v-for="item in news" :key="item.id" class="mb-2">
                      <router-link :to="{ name: 'NewsItem', params: { id: item.id }}">{{item.name}}</router-link><br />
                      <small>{{item.source}} - {{getDate(item.date)}}</small>
                    </div>
                </div>
              </b-col>
            </b-row>
          </b-container>
        </b-card>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import ac from '@/libs/accesscontrol'
import Subscription from '@/components/Subscription.vue'

export default {
  name: 'Tag',
  components: {
    Subscription
  },
  computed: {
    user: {
      get () {
        return this.$store.state.user
      }
    }
  },
  created: function () {
    this.$stat.log({ page: 'tag', action: 'open tag', model: 'label', model_id: this.$route.params.id })
    this.$store.commit('setBrand', 'Regulatory Horizon')
    this.permission.read = true
    this.permission.edit = ac.can(this.user.acgroups).updateAny('label').granted
    this.permission.editTopic = ac.can(this.user.acgroups).createAny('topicUpdate').granted
    this.permission.readTopicUpdate = ac.can(this.user.acgroups).readAny('topicUpdate').granted
    this.permission.addArticle = ac.can(this.user.acgroups).createAny('article').granted
    this.permission.addRisk = ac.can(this.user.acgroups).createAny('marrisk').granted
    this.permission.addTag = ac.can(this.user.acgroups).createAny('newstag').granted
    this.permission.articlesWip = ac.can(this.user.acgroups).createAny('articleWorkflowEdit').granted
    this.permission.deletePublication = ac.can(this.user.acgroups).deleteAny('publication').granted
    this.permission.insights = true
    this.permission.readTagsPanel = ac.can(this.user.acgroups).readAny('tagspanel').granted
    if (this.$config.BUILD === 'external') {
      this.permission.editTopic = false
      this.permission.readTopicUpdate = false
      this.permission.addRisk = false
      this.permission.addTag = false
      this.permission.deletePublication = false
      this.permission.insights = ac.can(this.user.acgroups).readAny('article').granted
      this.permission.readTagsPanel = false
    }
    this.load()
  },
  data () {
    return {
      getDate: function (input) {
        const d = new Date(Date.parse(input))
        return d.toLocaleDateString()
      },
      loading: true,
      loadingNews: true,
      item: null,
      news: [],
      permission: {
        edit: false,
        read: false
      },
      savingStep: false,
      tags: []
    }
  },
  methods: {
    load: async function () {
      this.loading = true
      try {
        this.item = await this.$Amplify.API.get('cosmos', `/label/${this.$route.params.id}`)
        document.title = "KPMG Regulatory Horizon: " + this.item.name
        this.loading = false
        this.tags = await this.$Amplify.API.get('cosmos', '/tags')
        this.news = await this.$Amplify.API.get('cosmos', `/news/label/${this.$route.params.id}/standardformat/limit/10`)
        this.loadingNews = false
      } catch (e) {
        this.$logger.warn(e)
      }
    }
  }
}
</script>
